
$galleryThumbMaxHeight: 120px;

.photoGallery {
	--swiper-navigation-color: #c0c0c0;
	text-align: center;

	.swiper-wrapper {
		align-items: center;
	}

	.swiper-pagination-bullet {
		background-color: #fff;
	}

	.imageContainer {
		display: flex;
		height: 100%;
		min-height: 100%;

		img {
			position: relative;
			object-fit: contain;
			width: 100%;
			height: 100%;
			z-index: 101;
		}
	}

	.galleryLarge {
		width: 100%;

		.imageContainer {
			img {
				max-height: 90vh;
			}
		}
	}

	.galleryThumbs {
		cursor: pointer;
		height: 10vh;
		max-height: $galleryThumbMaxHeight;
		box-sizing: border-box;
		padding: 10px 0;
	}

	.galleryThumbs .swiper-slide {
		width: 25%;
		height: 100%;
		max-height: $galleryThumbMaxHeight;
		opacity: 0.6;

		.imageContainer {
			img {
				max-height: $galleryThumbMaxHeight;
			}
		}
	}

	.galleryThumbs .swiper-slide-thumb-active {
		opacity: 1;
	}

	.swiper {
        align-self: center;
        width: 100%;
        height: 100%;
	}

}