$maxWidth: 1600px;

body {
	background: #fff;

	ion-app {
		opacity: 1;
		transition: opacity .8s;
		transition-delay: .2s;

		&:empty {
			opacity: 0;
		}

		ion-header {
			user-select: none;
			text-align: center;
			max-width: $maxWidth;
			margin: 0 auto;

			#logo-img {
				margin: 10px auto 5px;
				height: 46px;
			}

			&.header-md {
				&::after {
					bottom: 0;
					background-image: none;
					box-shadow: 0 5px 7px 2px rgba(0, 0, 0, .07);
				}
			}
		}

		ion-progress-bar {
			position: absolute;
			top: 0;
			left: 0;
			height: 5px;
			z-index: 999;
		}

		ion-content {
			--padding-top: 10px;
			--padding-start: 6px;
			--padding-end: 6px;
			--padding-bottom: 60px;
			align-self: center;
			max-width: $maxWidth;

			h3 {
				font-size: 20px;
			}

			ion-button {
				--background: none;
				--background-activated: #efefef;
				--background-hover: var(--ion-color-primary);
				--border-width: var(--button-border-width);
				--border-color: var(--button-border-color); 
				--border-radius: var(--button-border-radius);
				--border-style: var(--button-border-style);
				--box-shadow: 0 2px 2px 1px rgba(0, 0, 0, .12);
				--color: var(--ion-text-color);
				margin-bottom: 15px;
				text-transform: none;
				letter-spacing: -.01em;
				text-align: left;
				border-radius: 5px;
				border: 1px solid #5e5e5e;
				font-size: 18px;
			}

			ion-card {
				padding: 10px 15px;
			}
		}

		// Api Images
		.imageContainer {
			display: block;
		}

		/* Utility */
		.center {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 100%;
		}

		.smaller {
			font-size: .9em;
		}

		.smallest {
			font-size: .8em;
		}

		.tabBar {
			position: fixed;
			bottom: 0;
			display: flex;
			flex-direction: row;
			width: 100%;
			background: #fff;
			text-align: center;
			border-top: 1px solid #e0e0e0;

			a {
				display: flex;
				flex: 1 0 auto;

				ion-button {
					flex: 1 0 auto;
					--background: none;
					--box-shadow: none;
					--ripple-color: var(--ion-color-primary);

					ion-icon {
						font-size: 40px;
					}
				}

				/*
				&.active {
					ion-icon {
						filter: invert(.5) sepia(1) saturate(5) hue-rotate(340deg);
					}
				}
				*/
			}
		}

		/* Popovers */
		.large-popover {
			--width: calc(100vw - 30px);
			--max-width: 800px;

			#ion-react-wrapper {
				overflow: hidden;
			}

			&::part(content) {
				top: 120px;
				transform-origin: center top !important;
				box-shadow: 0 2px 8px 5px rgba(0, 0, 0,.3);
			}

			&::part(backdrop) {
				cursor: default;
			}

			&::-webkit-scrollbar {
				width: 13px;
			}
		
			&::-webkit-scrollbar-track {
				background: #f0f1f5;
			}
		
			&::-webkit-scrollbar-thumb {
				background-color: var(--ion-color-primary);
				border-radius: 6px;
				border: 3px solid #f0f1f5;
			}

			&.homeListMenuPopover {
				#ion-react-wrapper {
					> * {
						overflow-y: auto;
					}
				}
			}
		}

		.photoModal {
			--height: 100%;
			--width: 100%;
			--max-width: $maxWidth;

			ion-content {
				--padding-top: 0;
				--padding-start: 0;
				--padding-end: 0;
				--padding-bottom: 0;

				.photoGallery {
					display: grid;
					height: 100%;

					.galleryLarge {
						width: 100%;
				
						.imageContainer {
							img {
								max-height: 100vh;
							}
						}
					}

					.swiper-slide {
						height: auto;
					}
				}
			}
		}
	}
}