
.profile {

	ion-card {

		.photo {
			height: 100px;

			img {
				border-radius: 10%;
				width: 100px;
			}
		}

		ion-input {
			--padding-top: 10px;
		}

		ion-button.delete {
			border: none;
			--box-shadow: none;
			--color: var(--ion-color-primary);
		}
	}

}