
.landing {
	ion-content {
		max-width: 1200px;

		.homes {
			height: 144px;

			&.empty {
				margin-top: 2rem;
			}
		
			.swiper {
			
				.swiper-slide {
					align-items: baseline;
					justify-content: center;
					padding-top: 14px;
					width: 85px;
				
					.home {
						cursor: pointer;
						text-decoration: none;
						font-size: 22px;
						text-align: center;
					}
				
					.photo {
						cursor: pointer;
						height: 85px;
						width: 85px;
						margin: auto;
						text-align: center;
						border-radius: 10%;
						border: solid 2px #f6f5f1;
						overflow: hidden;
				
						.imageContainer {
							user-select: none;

							img {
								height: 85px;
								width: 85px;
								object-fit: cover;
							}
						}
				
						&.add {
							box-shadow: 0px 1px 5px 1px #f2f2f2;
				
							ion-img {
								margin: 6px 0 0 7px;
								width: 68px;
								height: 68px;
							}
						}
				
						&.nophoto {
							background: #f4efef;
				
							ion-img {
								margin: 14px 0 0 12px;
								width: 56px;
								height: 56px;
							}
						}
					}
				
					ion-label {
						display: inline-block;
						margin-top: 6px;
						font-size: 10px;
						width: 80px;
						height: 38px;
						overflow: hidden;
						color: #424242;
					}
				}
			}
		}
		
		.updates {
			padding: 0 15px;
			margin: 0 auto;

			.loadingIndicator {
				margin-top: 1.5rem;
			}
		
			h3 {
				user-select: none;
				font-size: 20px;
				margin: 0;
				padding: 0;
			}
		
			ion-grid {
				padding: 0;
				margin: 10px 0 0;
		
				ion-col {
					padding: 0;
		
					ion-card {
						padding: 17px 14px;
						margin: 0 0 10px;
		
						.header {
							display: flex;
		
							.creator {
								width: 50px;
								height: 50px;
								margin-right: 8px;

								img {
									width: 50px;
									height: 50px;
									object-fit: cover;
									user-select: none;

									&.photo {
										cursor: pointer;

										border-radius: 50%;
									}

									&.edgeLogo {
										margin: 0 8px 0 -4px;
									}
								}

								.loadingIndicator {
									margin-top: 0;
								}
							}
		
							h4 {
								margin: 5px 0 0;
								padding: 0;
								font-size: 15px;
								font-weight: 600;
		
								span {
									margin: 2px 0 0;
									padding: 0;
									display: block;
									font-size: 12px;
									font-weight: normal;
									color: #ababab;
								}
							}
						}
		
						.content {
							margin: 8px 0 2px 6px;
						}

						.attachments {
							display: grid;
							margin: 18px 0 0;
							justify-items: center;
							align-items: center;

							.loadingIndicator {
								margin: 100px 0;
							}

							.imageContainer {

								img {
									cursor: pointer;
									max-height: 600px;
									border-radius: 10px;
									object-fit: cover;
								}
							}
						}
					}
				}
			}
		}
	}
}