
.home {

	ion-content {

		.empty {
			margin-bottom: 40px;
		}

		.homeInfo {
			box-shadow: 0px 17px 27px -8px rgba(0, 0, 0, .08);
			padding: 8px 12px 10px;

			.loadingIndicator {
				margin-top: 2.4rem;
				margin-bottom: 1.8rem;
			}

			.errorContent {
				padding: 10vh 0;
			}

			.homeHeader {
				display: flex;
	
				> div {
					display: flex;
				}
	
				.name {
					flex: 0 1 auto;
	
					font-size: 24px;
					font-weight: bold;
					letter-spacing: -.01em;
				}
	
				.editGroup {
					flex: 1 1 auto;
					justify-content: flex-start;
					align-items: center;
	
					img {
						cursor: pointer;
	
						&.edit {
							margin: 0 16px 0 10px;
							height: 26px;
						}
	
						&.save {
							margin: 8px 10px 0 15px;
							height: 22px;
						}
	
						&.cancel {
							margin: 8px 8px 0 8px;
							height: 22px;
						}
					}
	
					ion-input {
						--padding-start: 10px;
						font-size: 22px;
					}
				}
	
				.homeListDropdown {
					flex: 0 0 25px;
					margin-left: 14px;
	
					ion-icon {
						cursor: pointer;
						font-size: 60px;
						color: var(--ion-color-primary);
						font-weight: bolder;
						padding-top: 10px;
						zoom: .5;
					}
				}
			}
	
			ion-button.remove {
				--background: var(--ion-color-primary);
				--color: #fff;
				margin: 0 auto 10px;
				border: none;
				box-shadow: none;
			}
	
			.homeMeta {
				flex-wrap: nowrap;
	
				.info {
					flex: 1 1 100%;
					align-self: center;
	
					.status {
						margin: 5px 0 8px;
					}
				}
			}
	
			.photo {
				cursor: pointer;
				user-select: none;
				width: 130px;
				height: 114px;
				text-align: center;
				border-radius: 10%;
				border: solid 2px #f6f5f1;
				overflow: hidden;
				padding: 0;
	
				&.nophoto {
					background: #f4efef;
					margin: 0;
	
					ion-img {
						margin: 14px auto;
						width: 74px;
						height: 74px;
					}
				}
	
				img {
					width: 130px;
					height: 110px;
					object-fit: cover;
				}

				.loadingIndicator {
					margin: 0;
				}
			}
	
			.contacts {
				text-align: center;
	
				ion-col {
					width: 33%;

					h3 {
						font-size: 14px;
						font-weight: 600;
						margin: 0;
						padding: 6px 0 2px;
					}
					
					div {
						cursor: pointer;

						span {
							font-size: 13px;
							color: var(--ion-color-primary);
						}
					}
				}
			}
		}
	
		ion-segment {
			justify-content: center;
			overflow-x: visible;
	
			ion-segment-button {
				font-size: 17px;
				text-transform: none;
				padding-top: 10px;
	
				&.segment-button-checked {
					font-weight: bold;
					color: #5e5e5e;
				}
			}
		}
	
		.segments {
			box-shadow:
				0px -2px 13px -1px rgba(0, 0, 0, .08),
				0px 27px 37px -20px rgba(0, 0, 0, .15)
			;

			> div {
				margin: 0;
				padding: 0;
				overflow: hidden;
				height: 0;
				opacity: 0;
				transform: translateY(10px);
				transition: height .5s ease, opacity .5s ease, transform .3s;
		
				&.selected {
					height: auto;
					opacity: 1;
					transform: translateY(0);
				}
			}
		
			.service {
				padding: 10px 20px;
				margin-bottom: 320px;

				.service-numbers {

					ion-card {
						margin: 0;
					}

					.number{
						text-align: center;
						padding: 10px 0;
						
						h3 {
							margin: 0;
							white-space: nowrap;
						}

						a {
							text-decoration: none;
						}
					}
				}

				.request {
					ion-card {
						margin: 0;

						h4 {
							margin-top: 20px;
							margin-bottom: 0;
							padding-bottom: 10px;
						}

						
						ion-input {
							--padding-top: 10px;
						}

						ion-textarea {
							--padding-start: 10px;
							margin-bottom: 5px;
						}
					}
				}
			}
	
			.progress {
				padding-top: 30px;

				ul.milestones {
					margin: 0;
					padding: 0;
					list-style: none;
		
					li {
						cursor: default;
						padding: 2px 30px 12px 20px;
						margin-left: 24px;
						border-left: 10px solid #d0d0d0;
		
						.milestone {
							user-select: none;
							position: relative;
							display: block;
							margin: 0 0 2px;
							font-size: 18px;
							font-weight: bold;
		
							&::before {
								content: "";
								color: #fff;
								position: absolute;
								top: -5px;
								left: -40px;
								height: 20px;
								width: 20px;
								background-color: #fff;
								border: 5px solid #d0d0d0;
								border-radius: 50%;
								display: inline-block;
								background-size: 20px 20px;
								background-repeat: no-repeat;
							}
		
						}
		
						.description {
							pointer-events: none;
							font-size: 14px;
							display: block;
							margin-top: 4px;
							opacity: 0;
							max-height: 0px;
							transition: opacity .25s ease-in-out,  max-height .25s ease-in-out;
						}
		
						&.active, &.complete {
							border-color: var(--ion-color-primary);
		
							.milestone {
								&::before {
									border-color: var(--ion-color-primary);
								}
							}
						}
		
						&.active, &.expanded {
							.description {
								pointer-events: auto;
								opacity: 1;
								max-height: 1000px;
							}
						}
		
						&.lastActive {
							border-left: 10px solid #d0d0d0;
		
							.milestone {
								&::before {
									border-color: var(--ion-color-primary);
								}
							}
						}
		
						&.complete {
							.milestone {
								&::before {
									background-image: url('./../../assets/icon/common/check-mark.png');
									background-color: var(--ion-color-primary);
									border-color: var(--ion-color-primary);
								}
							}
						}
		
						&:last-of-type {
							border-left-color: transparent;
						}
					}
				}
			}

			.design {
				padding-top: 10px;

				h1 {
					margin: 30px 30px 20px;
				}

				.card-container {
					padding: 0 10px 30px 10px;
				
					h1 {
						margin: 16px 0 20px;
					}
					
					p {
						padding: 0px;
					}
					
					span {
						color: gray;
					}
				}

				.optionsGallery {
					--swiper-navigation-color: #c0c0c0;
					text-align: center;

					.swiper-pagination-bullet {
						background-color: #fff;
					}

					img {
						height: 520px;
						object-fit: cover;
					}
				}

				.image-container {
					padding: 0;
				}
			}
		
			.photos {
				padding: 20px 0 10px;

				.empty {
					margin-top: 20px;
				}
				
				.loadingIndicator {
					margin-top: 2.4rem;
					margin-bottom: 3.2rem;
				}

				&.photoGallery {
					.galleryLarge {
						height: calc(100vh - 620px);
						min-height: 360px;
						max-height: 1100px;
					}
				}
			}
		}
	}
}