
.contact {

    ion-content {
        --padding-start: 15px;
        --padding-end: 15px;
        
        ion-card {
            position: relative;
            padding: 10px 15px 0;
            background: #ffffff;
            text-align: center;
            max-width: 600px;
            margin: 20px auto 0;

            .close {
                cursor: pointer;
                position: absolute;
                top: 10px;
                right: 10px;
                font-size: 40px;
                z-index: 1;
            }

            .avatar {
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 30px auto 10px;
                width: 240px;
                height: 240px;

                img {
                    min-width: 200px;
                    min-height: 200px;
                    border-radius: 10%;
                    overflow: hidden;
                }

                .notFound {
                    margin-top: 50px;
                }
            }

            .title {
                text-align: center;
            }

            .name {
                font-size: 20px;
                font-weight: bold;
                text-align: center;
            }

            ion-button {
                width: 200px;
                margin-bottom: 15px;
                font-size: 16px;

                &.message {
                    margin-top: 15px;
                }
            }
        }
    }

}