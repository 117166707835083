
.explore {

	ion-content {
		--padding-top: 5px;
	
		h2 {
			padding: 15px 0 5px 25px;
		}
	
		.header-image {
			overflow: hidden;
			
			max-height: 600px;
			object-fit: cover;
		}
	
		ion-card {
			cursor: pointer;
			padding: 0;
			margin: 0 15px 15px;
	
			ion-grid {
				padding: 5px;
			}
	
			ion-col {
				padding: 0;
			}
	
			.icon {
				display: flex;
				flex: 0 1 70px;
				padding-left: 1px;
	
			}
	
			.financing {
				margin-left: 7px;
			}
	
			.title {
				height: 35%;
				font-weight: bolder;
			}
	
			.description {
				font-size: 14px;
			}
		}
	}

}