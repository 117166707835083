
.homeAdd {

	ion-content {
		$top: 1vh;

		img.home {
			position: absolute;
			top: calc(20px + #{$top});
			height: 360px;
			left: 50%;
			transform: translate(-50%, -88px);
		}

		h1 {
			text-transform: uppercase;
			text-align: center;
			font-size: 19px;
			font-weight: bold;
			margin-top: calc(200px + #{$top});
		}

		.access-code {
			margin: 90px auto 320px;
			width: 85%;
			max-width: 500px;

			span {
				display: inline-block;
				font-size: 16px;
				line-height: 22px;
				color: #8c8c8c;
				text-align: center;
				margin: 0 0 8px 20px;
			}

			.wrapper {
				display: flex;
				align-items: center;

				ion-input {
					margin-left: 10px;
					font-size: 20px;
					--padding-start: 10px;
				}

				ion-button {
					background-color: #ff8200;
					color: white;
					margin: 0 0 0 8px;
				}
			}
		}
	}

}