.homeListMenu {
	--margin: 0;
	padding: 0;
	max-height: 60vh;

	/*
	// NEEDED?
	&.list-ios {
		margin-bottom: 0;
	}
	*/

	.loadingIndicator {
		margin: 30px auto 15px;
		text-align: center;
	}

	> .loadingIndicator {
		margin-bottom: 30px;
	}

	.home {
		margin-top: 10px !important;
		margin-bottom: 10px !important;

		.add {
			ion-img {
				width: 60px;
			}
		}

		$photoWidth: 72px;
		$photoHeight: 72px;

		ion-col {
			cursor: pointer;
			display: flex;
			padding: 0;
			align-items: center;
			user-select: none;
			align-content: center;
			justify-content: flex-start;
			flex: 1 1 calc(100% - #{$photoWidth});
			height: $photoHeight;

			&:first-of-type {
				margin: 0 auto;
				flex: 1 0 $photoWidth;

				> * {
					margin: 0 auto;
				}
			}

			.photo {
				border-radius: 10%;
				width: $photoWidth;
				height: $photoHeight;
				border: solid 2px #f6f5f1;
				overflow: hidden;
				padding: 0;

				&.nophoto {
					background: #f4efef;
					margin: 0;
					padding: 8px;
				}

				object-fit: cover;
			}

			&.name {
				span {
					font-weight: bold;
				}
			}
		}

	}

}