
.login {
	ion-header {
		#logo-img {
			height: 50px;
			margin: 55px auto 20px;
		}

		&.header-md {
			&::after {
				box-shadow: none;
			}
		}
	}

	ion-content {
		--background: url(/assets/images/img-signin.jpg) no-repeat center;
		max-width: none;

		.loginForm {
			position: absolute;
			width: 400px;
			top: calc(max(100px, 32%));
			left: 50%;
			transform: translate(-50%, -50%);
			text-align: center;

			h2.instructions {
				span {
					user-select: none;
					display: block;
					font-size: 16px;
					line-height: 22px;
					color: #8c8c8c;
					text-align: center;
					margin: 0 0 8px 20px;
				}
			}

			.inputRow {
				display: flex;
				align-items: center;
				justify-content: center;

				ion-input {
					margin: 0 5px;
					font-size: 20px;
					--background: #fff;
				}

				.fakeCaret {
					pointer-events: none;
					position: relative;
					overflow: visible;
					animation: blink 1.5s infinite;
					opacity: 1;
					width: 1px;
					z-index: 1000;

					span {
						position: absolute;
						top: -12px;
						left: -34px;
						display: block;
						height: 24px;
						border-left: 2px solid var(--ion-color-primary);
					}
				}
			}

			ion-item {
				--border-color: transparent;
			}

			ion-item-group {
				background: #fff;
				border-radius: 5px;
				padding: 10px 15px 30px 5px;
			}

			.recaptcha {
				margin: 0 0 0 28px;
			}
		}

		ion-button {
			--background: #ff8200;
			--color: white;
			margin: 0 0 0 8px;
		}

		.privacy,
		.retry {
			margin: 25px 40px 0;
			text-align: center;

			a {
				padding: 0 2px 0 4px;
				text-decoration: none;
			}
		}
		 
		.privacy {
			font-size: 11px;
			opacity: 0.6;
		}

		.retry {
			font-size: 12px;
			opacity: 0.85;

			ion-button {
				margin-top: -4px;
			}
		}
	}
}

@keyframes blink {
    0% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 1; }
}

.grecaptcha-badge { 
    visibility: hidden !important;
}


// Mobile
@media (max-width: 767.98px) {
	.login {
		ion-header {
			#logo-img {
				margin: 20px auto 10px;
			}
		}
	}
}